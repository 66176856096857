import React from "react";
import { TypographyV2, Typography } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./RoiV2.module.scss";

export const RoiV2 = () => {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<TypographyV2 className={styles.title}>
					Centralize all research processes within a single,
					comprehensive software solution
				</TypographyV2>
				<div className={styles.imageContainer}>
					<StaticImage
						loading="eager"
						src="./asssets/roi.png"
						alt="roiimage"
						className={styles.image}
					/>
					<StaticImage
						loading="eager"
						src="./asssets/mobileroi.png"
						alt="roiimage"
						className={styles.image2}
					/>
				</div>
				<TypographyV2
					color="text-helper"
					className={styles.description}
				>
					Enhance clinical research breakthroughs with Genemod's
					integrated approach to data management.
				</TypographyV2>
			</div>
		</div>
	);
};

import React, { FC } from "react";
import CardWithIcon, {
	CardWithIconProps,
} from "@components/CardWithIcon/CardWithIcon";
import * as styles from "./LabConsumablesCards.module.scss";
import { TypographyV2 } from "@components/index";
import { Link } from "gatsby";
import { LINKS } from "@components/Link/Link";
import classNames from "classnames";

interface LabConsumablesCardsProps {
	cards: CardWithIconProps[];
	useTitleV4Styles?: boolean;
	hideSignUpButton?: boolean;
	titleV4StylesOverride?:string;
	sectionWrapperClassName?:string;
}

const LabConsumablesCards: FC<LabConsumablesCardsProps> = (props) => {
	let titleStyles = props?.useTitleV4Styles
		? classNames(styles.title, props?.titleV4StylesOverride)
		: classNames(styles.title, styles.overrideFonts);
	return (
		<div className={classNames(styles.content, props?.sectionWrapperClassName)}>
			<div className={styles.text}>
				<TypographyV2
					variant="HEADING_2"
					tabletVariant="HEADING_3"
					mobileVariant="HEADING_4"
					weight="MEDIUM"
					className={titleStyles}
				>
					The Modern R&D platform that can scale with your custom or
					high-volume needs
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_EXTRA_LARGE"
					mobileVariant="BODY_TEXT_LARGE"
					color="text-helper"
					className={styles.centeredText}
				>
					Tailor Genemod for site-wide licenses of any scale
				</TypographyV2>
			</div>
			<div className={styles.cards}>
				{(props.cards ?? [])?.map((card, index) => {
					return <CardWithIcon {...card} key={index} />;
				})}
			</div>
			{!props?.hideSignUpButton && (
				<Link to={LINKS.SIGN_UP.to} className={styles.button}>
					<TypographyV2
						variant="BODY_TEXT_LARGE"
						weight="MEDIUM"
						color="base-white"
						style={{ margin: 0 }}
						className={styles.CTA}
					>
						Sign Up for Free
					</TypographyV2>
				</Link>
			)}
		</div>
	);
};

export default LabConsumablesCards;

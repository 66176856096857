// extracted by mini-css-extract-plugin
export var bottom = "FeatureCard-module--bottom--TqyMe";
export var card = "FeatureCard-module--card--Z+sNy";
export var card_font = "FeatureCard-module--card_font--29+oI";
export var container = "FeatureCard-module--container--W+HzK";
export var font = "FeatureCard-module--font--h+25Q";
export var hideOnTablet = "FeatureCard-module--hide-on-tablet--A2Lgr";
export var hide_on_tablet = "FeatureCard-module--hide_on_tablet--rzHXv";
export var imageClassName_font = "FeatureCard-module--imageClassName_font--1pX31";
export var layout = "FeatureCard-module--layout--DqeW2";
export var limitWidthOnLargeScreens = "FeatureCard-module--limitWidthOnLargeScreens--uTcHi";
export var list = "FeatureCard-module--list--bO6jT";
export var listElement = "FeatureCard-module--listElement--hQ-++";
export var listElementText = "FeatureCard-module--listElementText--nqNy9";
export var list_font = "FeatureCard-module--list_font--UF5hg";
export var right = "FeatureCard-module--right--oqLfs";
export var tablet_title = "FeatureCard-module--tablet_title--xuFwE";
export var title = "FeatureCard-module--title--A3Qdg";
export var top = "FeatureCard-module--top--8Gb5R";
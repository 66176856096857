import React, { useState } from "react";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./FaqV2.module.scss";

interface FaqItem {
	question: string;
	answer: string;
}

interface FaqV2Props {
	faqItems: FaqItem[];
}

const FaqItem: React.FC<
	FaqItem & { isOpen: boolean; toggleOpen: () => void }
> = ({ question, answer, isOpen, toggleOpen }) => {
	return (
		<div>
			<div className={styles.FaqItem}>
				<TypographyV2 className={styles.question}>
					{question}
				</TypographyV2>
				<div onClick={toggleOpen} className={styles.toggle}>
					{isOpen ? (
						<StaticImage src="./assets/minus.svg" alt="minus" />
					) : (
						<StaticImage
							src="./assets/chevron-down.svg"
							alt="expand"
						/>
					)}
				</div>
			</div>
			{isOpen && (
				<TypographyV2 className={styles.answer}>{answer}</TypographyV2>
			)}
		</div>
	);
};

export const FaqV2: React.FC<FaqV2Props> = ({ faqItems }) => {
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const toggleOpen = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<div className={styles.container}>
			<TypographyV2 weight="MEDIUM" className={styles.title}>
				Frequently asked questions
			</TypographyV2>
			<div>
				{faqItems.map((item, index) => (
					<FaqItem
						key={index}
						{...item}
						isOpen={openIndex === index}
						toggleOpen={() => toggleOpen(index)}
					/>
				))}
			</div>
		</div>
	);
};

// extracted by mini-css-extract-plugin
export var bodyText = "Hero-module--bodyText--8Sl48";
export var buttons = "Hero-module--buttons--qlDZF";
export var generalSansFont_bodyText = "Hero-module--generalSansFont_bodyText--m7vMT";
export var generalSansFont_headerText = "Hero-module--generalSansFont_headerText--6mpjh";
export var headerText = "Hero-module--headerText--mIykq";
export var hero = "Hero-module--hero--52veU";
export var heroImage = "Hero-module--heroImage--HBA3k";
export var limitWidthOnLargeScreens = "Hero-module--limitWidthOnLargeScreens--+Jcrh";
export var tabletAlignmentCenter = "Hero-module--tabletAlignmentCenter--WFanb";
export var textButtonsContainer = "Hero-module--textButtonsContainer--vUG5l";
export var textLinesAlignment = "Hero-module--textLinesAlignment--tNSC8";
export var wrapper = "Hero-module--wrapper--KZkPL";
import {
	RequestDemoButtonV2,
	SignUpButtonV2,
} from "@components/Button_new/Button";
import { TypographyV2 } from "@components/index";
import React, { HTMLProps } from "react";
import * as styles from "./Hero.module.scss";
import classNames from "classnames";

type TextProps = {
	title: string;
	description: string;
	tabletTextAlignment?: "left" | "center";
	heroTextContainerSize?: "default" | "small";
	textButtonsContainerOverride?: string;
	headerTextOverride?: string;
	bodyTextOverride?: string;
	generalSansFont?: boolean;
};

type HeroProps = TextProps & {
	image: any;
	tabletTextAlignment?: "left" | "center";
	heroTextContainerSize?: "default" | "small";
	textButtonsContainerOverride?: string;
	headerTextOverride?: string;
	bodyTextOverride?: string;
	generalSansFont?: boolean;
} & HTMLProps<HTMLDivElement>;

const TextButtonsContainer = (props: TextProps) => {
	return (
		<div
			className={classNames(styles.textButtonsContainer, {
				[styles.tabletAlignmentCenter]:
					props.tabletTextAlignment === "center",
				[styles.textLinesAlignment]:
					props.heroTextContainerSize === "small",
			})}
		>
			<div
				className={classNames(styles.wrapper, {
					[styles.wrapperCenter]:
						props.tabletTextAlignment === "center",
				})}
			>
				<TypographyV2
					weight="BOLD"
					variant="HEADING_1"
					className={classNames(
						styles.headerText,
						props.generalSansFont &&
							styles.generalSansFont_headerText
					)}
				>
					{props.title}
				</TypographyV2>
				<TypographyV2
					weight="REGULAR"
					className={classNames(
						styles.bodyText,
						props.generalSansFont && styles.generalSansFont_bodyText
					)}
				>
					{props.description}
				</TypographyV2>
			</div>
			<div className={styles.buttons}>
				<RequestDemoButtonV2 />
				<SignUpButtonV2 />
			</div>
		</div>
	);
};

export const Hero = (props: HeroProps) => {
	return (
		<div className={classNames(styles.hero, props.className)}>
			<TextButtonsContainer
				title={props.title}
				description={props.description}
				tabletTextAlignment={props.tabletTextAlignment}
				heroTextContainerSize={props.heroTextContainerSize}
				textButtonsContainerOverride={
					props?.textButtonsContainerOverride
				}
				headerTextOverride={props?.headerTextOverride}
				bodyTextOverride={props?.bodyTextOverride}
				generalSansFont={props.generalSansFont}
			/>
			<div className={styles.heroImage}>{props.image}</div>
		</div>
	);
};

import React from "react";
import { PageLayout } from "@components/index";
import { EquipmentHero } from "./_components/_EquipmentHero/_EquipmentHero";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import Margins from "@components/Margins/Margins";
import * as styles from "./equipments.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { RoiV2 } from "@components/RoiV2/RoiV2";
import LabConsumablesCards from "pages/products/lab-consumables/_components/LabConsumablesCards";
import { FaqV2 } from "@components/FAQV2/FaqV2";

import { FaqProps } from "../reports";
import { CardWithIconProps } from "@components/CardWithIcon/CardWithIcon";

const faqItems: FaqProps[] = [
	{
		question: "What is the Equipment Management feature?",
		answer: "Genemod’s Equipment Management feature streamlines the management of scientific instruments, enabling smoother lab operations. It provides easy scheduling, booking, and maintenance of equipment, ensuring that your team’s research activities remain organized and uninterrupted.",
	},

	{
		question: "Can I customize the Equipment Management feature?",
		answer: "Yes, the Equipment Management feature is customizable. You can add the specific research instruments your team uses, along with images and descriptions. Additionally, you can schedule and book these instruments for research activities according to your team's needs.",
	},
	{
		question: "What are the benefits of the Equipment Management feature?",
		answer: "In scientific research, it’s easy for instruments, both large and small, to become misplaced, fall behind on maintenance, or have scheduling conflicts. Imagine being ready for a crucial experiment, only to find that the equipment you need is missing or in use by someone else. Genemod's Equipment Management feature helps prevent these situations by keeping track of equipment locations, maintenance schedules, and availability, ensuring your research runs smoothly.",
	},
];

const cards: FeatureCardProps[] = [
	{
		title: "Visualize instrument availability and scheduling",
		tabletTitle: true,
		tabletTitleText:
			"Use the most innovative technology of all time for free on Genemod",
		image: (
			<StaticImage
				src={"./_assets/equipment_img_1.png"}
				alt="Visualize Instrument Availability and Scheduling"
			/>
		),
		list: [
			"Get a complete view of all available instruments to optimize usage and minimize downtown.",
			"Use the visual schedule to quickly see when equipment is booked, making planning easier and reducing overlaps.",
			"Promote effective coordination among team members to optimize instrument usage and keep up a continuous workflow.",
		],
	},
	{
		title: "User-friendly booking experience",
		image: (
			<StaticImage
				src={"./_assets/equipment_img_2.png"}
				alt="User-Friendly Booking Experience"
			/>
		),
		list: [
			"Our design makes booking a breeze – just click, drag, and secure your equipment for your preferred date and time.",
			"The platform intuitively displays availability to prevent double-bookings and improve collaboration.",
			"Simplify the reservation process by giving researchers access to the tools they need precisely when they need them.",
		],
		orientation: "right",
	},
	{
		title: "Comprehensive equipment management",
		image: (
			<StaticImage
				src={"./_assets/equipment_img_3.png"}
				alt="Comprehensive Equipment Management"
			/>
		),
		list: [
			"Maintain a detailed list of instruments for easy access and timely usage.",
			"Empower team members to add, modify, or even track maintenance schedules so that equipment stays reliable and long-lasting.",
			"Foster a proactive approach to equipment care, maximizing performance and reducing unexpected disruptions.",
		],
	},
];

const labConsumablesCards: CardWithIconProps[] = [
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_2707)">
					<path
						d="M19 2.99976H9C7.89543 2.99976 7 3.89519 7 4.99976V14.9998C7 16.1043 7.89543 16.9998 9 16.9998H19C20.1046 16.9998 21 16.1043 21 14.9998V4.99976C21 3.89519 20.1046 2.99976 19 2.99976Z"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M17 16.9998V18.9998C17 19.5302 16.7893 20.0389 16.4142 20.414C16.0391 20.789 15.5304 20.9998 15 20.9998H5C4.46957 20.9998 3.96086 20.789 3.58579 20.414C3.21071 20.0389 3 19.5302 3 18.9998V8.99976C3 8.46932 3.21071 7.96061 3.58579 7.58554C3.96086 7.21047 4.46957 6.99976 5 6.99976H7"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_2707">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "A source of truth for your inventory",
		description:
			"From reagents to custom item types - we customize to match exactly how it is in the lab.",
		to: "INVENTORY",
		Cta: "Virtual freezers",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_3509)">
					<path
						d="M12 9.99976C12 8.40846 11.3679 6.88233 10.2426 5.75712C9.11742 4.6319 7.5913 3.99976 6 3.99976H3V5.99976C3 7.59105 3.63214 9.11718 4.75736 10.2424C5.88258 11.3676 7.4087 11.9998 9 11.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 13.9998C12 12.4085 12.6321 10.8823 13.7574 9.75712C14.8826 8.6319 16.4087 7.99976 18 7.99976H21V8.99976C21 10.5911 20.3679 12.1172 19.2426 13.2424C18.1174 14.3676 16.5913 14.9998 15 14.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 19.9998V9.99976"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_3509">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Finally, an ELN built for scientists",
		description:
			"It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
		to: "NOTEBOOK",
		Cta: "Explore ELN",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 1.99976C12.5917 1.99976 13.1713 2.05114 13.7348 2.14969M12 1.99976C6.47715 1.99976 2 6.47691 2 11.9998C2 17.5226 6.47715 21.9998 12 21.9998M12 1.99976V21.9998M17.738 3.80876C18.6922 4.47845 19.5241 5.31065 20.1934 6.26516M21.8501 10.2654C21.9486 10.8287 22 11.4082 22 11.9998C22 12.5913 21.9486 13.1708 21.8501 13.7341M20.1892 17.7404C19.5203 18.6927 18.6896 19.5231 17.7369 20.1915M13.7328 21.8502C13.17 21.9485 12.591 21.9998 12 21.9998"
					stroke="#2C52F7"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		),
		title: "Transparent and scalable pricing",
		description:
			"Finally, simple and predictable pricing that scales with your team.",
		to: "PRICING",
		Cta: "View Pricing",
	},
];

const Equipments = () => {
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "equipments" }}>
			<Margins className={styles.roibg}>
				<EquipmentHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return (
							<FeatureCard
								font
								sectionAlignment
								{...card}
								key={index}
							/>
						);
					})}
				</FeatureCardLayout>
				<RoiV2 />
				<div className={styles.integrated_bg}>
					<JoinInstitutionsV2
						outerContainerClassName={styles.outerContainerClassName}
					/>
				</div>
				<LabConsumablesCards cards={labConsumablesCards} />
				<div className={styles.faqbg}>
					<FaqV2 faqItems={faqItems} />
				</div>
			</Margins>
		</PageLayout>
	);
};

export default Equipments;

import TypographyV2, { Weight } from "@components/TypographyV2/TypographyV2";
import classNames from "classnames";
import React, { HTMLProps } from "react";
import * as styles from "./FeatureCard.module.scss";
import { Colors } from "@helpers/Colors";

export type FeatureCardProps = {
	title?: string;
	subtitle?: string;
	key?: string;
	list: string[];
	image: JSX.Element;
	orientation?: "left" | "right";
	imageBottom?: boolean;
	sublist?: string[];
	className?: string;
	font?: boolean;
	sectionAlignment?: boolean;
	listItemWeight?: Weight;
	titleStylesOverride?: string;
	sublistColor?: Colors;
	tabletTitle?: boolean;
	tabletTitleText?: string;
};

const FeatureCard = ({
	title,
	subtitle,
	list,
	sublist,
	image,
	font = false,
	sectionAlignment = false,
	orientation = "left",
	imageBottom = false,
	className,
	listItemWeight = "MEDIUM",
	titleStylesOverride,
	sublistColor,
	tabletTitle = false,
	tabletTitleText,
}: FeatureCardProps) => {
	let imageClassName = styles.image;
	let listClassName = styles.list;

	if (imageBottom) {
		imageClassName = classNames(imageClassName, styles.bottom); // add 'bottom' class if imageBottom is true
		listClassName = classNames(listClassName, styles.top); // add 'top' class if imageBottom is true
	}

	return (
		<div className={classNames(styles.container, className)}>
			<TypographyV2
				weight="MEDIUM"
				className={classNames(
					styles.title,
					font && styles.font,
					tabletTitle && styles.hide_on_tablet,
					titleStylesOverride
				)}
			>
				{title}
			</TypographyV2>
			{subtitle && (
				<TypographyV2
					weight="REGULAR"
					variant="HEADING_3"
					tabletVariant="HEADING_3"
					mobileVariant="HEADING_3"
					color="text-helper"
					center
				>
					{subtitle}
				</TypographyV2>
			)}
			{tabletTitle && (
				<TypographyV2
					weight="MEDIUM"
					className={classNames(
						styles.title,
						styles.tablet_title,
						font && styles.font
					)}
				>
					{tabletTitleText}
				</TypographyV2>
			)}
			<div
				className={classNames(
					sectionAlignment && styles.card_font,
					styles.card,
					styles[orientation]
				)}
			>
				<div
					className={classNames(
						font && styles.listsClassNames_font,
						listClassName
					)}
				>
					{list.map((item, index) => {
						return (
							<div
								className={classNames(
									font && styles.listElement_font,
									styles.listElement
								)}
								key={index}
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										minWidth: 24,
										minHeight: 24,
									}}
								>
									<path
										d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
										fill="#F5F7FF"
									/>
									<path
										d="M24 10L13 21L8 16"
										stroke="#5D7AF9"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<div className={styles.listElementText}>
									<TypographyV2
										variant="BODY_TEXT_EXTRA_LARGE"
										weight={listItemWeight}
										color="text-secondary"
										mobileVariant="BODY_TEXT_LARGE"
										className={classNames(
											font && styles.list_font
										)}
										style={{
											margin: 0,
										}}
									>
										{item}
									</TypographyV2>
									{sublist &&
										sublist.length > 0 &&
										sublist[index] && (
											<TypographyV2
												variant="BODY_TEXT_EXTRA_LARGE"
												weight="REGULAR"
												color={
													sublistColor ??
													"text-primary"
												}
												mobileVariant="BODY_TEXT_LARGE"
												style={{
													margin: 0,
												}}
												className={classNames(
													font && styles.list_font
												)}
											>
												{sublist[index]}
											</TypographyV2>
										)}
								</div>
							</div>
						);
					})}
				</div>
				<div
					className={classNames(
						font && styles.imageClassName_font,
						imageClassName
					)}
				>
					{image}
				</div>
			</div>
		</div>
	);
};

export default FeatureCard;

type FeatureCardLayoutProps = HTMLProps<HTMLDivElement>;

export const FeatureCardLayout = ({
	children,
	...props
}: FeatureCardLayoutProps) => {
	return (
		<div {...props} className={classNames(styles.layout, props.className)}>
			{children}
		</div>
	);
};
